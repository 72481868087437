<template>
  <div v-if="switchList">
    <img class="banner d-lg-block d-none" src="~assets/images/img/appBanner.jpg"/>
    <div class="about container-fluid">
      <div class="row">
        <div class="col-12 col-lg-2" style="padding: 0">
          <switch-bar :switchList="switchList"
                      @switchItem="switchItem"
                      :activeIndex="Number(activeIndex)"/>
        </div>
        <div class="col-12 col-lg-9 aboutRight">
          <div class="rightTitle">
            <div class="rightTitleValue d-lg-block d-flex align-items-center">
              <p class="rightTitleValueC">{{showTitle}}</p>
              <p class="rightTitleValueE">{{showTitleE}}</p>
            </div>
            <div class="rightTitleLinks d-none d-lg-flex">
              <p>当前位置：</p>
              <router-link class="link" to="/home" tag="p">首页</router-link>
              >
              <!--刷新页面并且更新模块为第一块-->
              <router-link class="link" :to="{
                path:switchList.link,
                query:{
                  index:0,
                  category_id:switchList && switchList.childList[0].category_id?switchList.childList[0].category_id :undefined
                }}" tag="p">{{switchList.text}}
              </router-link>
              >
              <p class="linkLast">{{showTitle}}</p>
            </div>
          </div>
          <!--内容块-->
          <div class="WapBoxPadding" v-if="industrialList">
            <div class="row">
              <div class="col-lg-4 col-6 industrialCoat" v-for="item in industrialList" @click="industrial(item.path)">
                <div class="industrial">
                  <div class="industrialImgCoat">
                    <img class="industrialImg" :src="item.image"/>
                  </div>
                  <div class="industrialInfo">
                    <p class="industrialTitle">{{item.name}}</p>
                    <div class="industrialButCoat"><p class="industrialBut">MORE →</p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--内容块 END-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import SwitchBar from "../../components/content/SwitchBar";
  import {getProductList} from 'network/product';
  export default {
    name: "Industrial",
    components: {SwitchBar},
    data() {
      return {
        //当前活跃的块下标
        activeIndex: 0,
        tid:null,
        industrialList:[],
      }
    },
    methods: {
      switchItem(index,tid) {
        if (index != this.$route.query.index) {
          if (tid){
            this.$router.push({
              path: this.$route.path,
              query: {
                index: index,
                category_id:tid
              }
            })
          }else {
            this.$router.push({
              path: this.$route.path,
              query: {
                index: index
              }
            })
          }
        }
      },
      getProductList(){
        this.tid=this.$route.query.category_id;
        getProductList(Number(this.tid)).then(res=>{
          this.industrialList=res.data.right_category_1;
        })
      },
      //传参跳转链接
      industrial(path){
        // console.log(this.getAppList);
        let index=-1;
        for (let i=0;i<this.getAppList.length;i++){
          if (this.getAppList[i].path==path){
            index=i;
            break;
          }
        };
        this.$router.push({
          path:'/industryapp',
          query:{
            index:index,
            category_id:path
          }
        })
      },
    },
    computed: {
      switchList() {
        //从vuex中取出对应的数据 ==>store ==>index
        if (this.$store.state.navInfo[1].childList){
          return this.$store.state.navInfo[1];
        }
      },
      // 当前活跃块的中文标题
      showTitle() {
        return this.switchList.childList[this.activeIndex].title || this.switchList.childList[this.activeIndex].name
      },
      // 当前活跃块的英文标题
      showTitleE() {
        return this.switchList.childList[this.activeIndex].titleE || this.switchList.childList[this.activeIndex].en_name
      },
      //获取行业应用的列表用作判断
      getAppList(){
        return this.$store.state.navInfo[2].childList;
      }
    },
    created() {
      this.getProductList();
      this.activeIndex = this.$route.query.index;
    },

    watch: {
      '$route'() {
        this.activeIndex = this.$route.query.index;
        this.getProductList();
      }
    }
  }
</script>
<style scoped>
  /* 小屏幕（平板，大于等于 768px） */
  @media (max-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .6rem 3%;
      border-bottom: 1px solid #e2e2e2;
    }
    .WapBoxPadding{
      padding:0 3%;
    }
  }

  /* 中等屏幕（桌面显示器，大于等于 992px） */
  @media (min-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: .35rem;
      border-bottom: 1px solid #e2e2e2;
    }

    .rightTitleValueC {
      margin-bottom: .2rem;
    }
  }
</style>
<style scoped>
  .industrialCoat{
    padding: 0 .3rem;
    margin-bottom: .6rem;
  }
  .industrial{
    cursor: pointer;
  }
  .industrialCoat:hover .industrialTitle{
    color: #fff;
    transition: color .3s;
  }
  .industrialCoat:hover .industrialInfo{
    background-color: var(--red);
    transition: background-color .6s;
  }
  .industrialCoat:hover .industrialBut{
    background-color: #fff;
    transition: background-color .6s;
  }
  .industrialImgCoat{
    width: 100%;
    height: 4rem;
    overflow: hidden;
  }
  .industrialImgCoat:hover .industrialImg{
    transform: scale(1.2,1.2);
    transition:transform .6s;
  }
  .industrialImg{
    width: 100%;
    height: 100%;
    transition: all .3s linear;
  }
  .industrialInfo{
    padding: .4rem .35rem;
    background-color: #f7f7f7;
    min-height: 1.4rem;
    transition: all .3s linear;
  }
  .industrialTitle{
    font-size: .45rem;
    color: #494949;
    overflow: hidden;
    transition: all .3s linear;
    text-overflow:ellipsis;
    text-align: center;
    }
  .industrialButCoat{
    text-align: end;
  }
  .industrialBut{
    display: inline-flex;
    padding: .1rem .45rem;
    border-radius: 30px;
    border: 1px solid var(--red);
    font-size: .3rem;
    color: var(--red);
    transition: all .3s linear;
  }

  .banner{
    width: 100%;
    height: auto;
  }
  .about {
    padding: 1.65rem var(--switchMarginX);
  }

  .rightTitle {
    margin-bottom: .55rem;
  }

  .rightTitleValueC {
    font-size: .6rem;
    color: #474747;
    margin-right: .4rem;
  }

  .rightTitleValueE {
    font-size: .35rem;
    color: #b2b2b2;
    padding: 0;
  }

  .rightTitleLinks {
    display: flex;
    align-items: center;
    font-size: .35rem;
    color: #b2b2b2;
    cursor: pointer;
  }

  .rightTitleLinks .link:hover {
    color: var(--red);
    transition: color .3s;
  }

  .rightTitleLinks .linkLast {
    color: #737373;
  }
</style>